import React, { createContext, useEffect } from 'react'
import { injected } from 'src/connectors'
import { useWeb3React } from '@web3-react/core'
import { ACTIVE_NETWORK } from 'src/constants'
export const UserContext = createContext()

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress)
  } else {
    sessionStorage.removeItem('userAddress')
  }
}

export default function AuthProvider(props) {
  const { activate, account, chainId } = useWeb3React()
  const connectToWallet = (data) => {
    const connector = injected
    window.sessionStorage.removeItem('walletName')
    window.sessionStorage.setItem('walletName', 'METAMASK')
    // setErrorMsg("");
    // setSuccessMSG("");
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log('error', error.message)
        // setErrorMsg(error.message + " Please install " + data.name);
        activate(connector)
        // setIsLoading(false);
        // setErrorPop(true);
      }
    })
  }
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler()
        }
      }
    }
  }, [chainId, account])
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      })
    } catch (error) {
      console.log('ERROR', error)

      // toast.warn(error.message)
      // if (error.code === 4902) {
      //   addNetworkHandler();
      // }
    }
  }
  let data = {
    updateUser: (account) => {
      setSession(account)
    },
    connectToWallet: () => connectToWallet(),
  }

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem('userAddress')
    if (userAddress) {
      data.connectWallet()
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    data.updateUser(account)
  }, [account]) //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  )
}
