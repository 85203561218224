import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { injected } from 'src/connectors'
import { useWeb3React } from '@web3-react/core'
import { ACTIVE_NETWORK } from 'src/constants'
import { getWeb3Obj } from 'src/utils'
export const AuthContext = createContext()

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('creatturAccessToken', accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('creatturAccessToken')
    delete axios.defaults.headers.common.Authorization
  }
}

function checkLogin() {
  const accessToken = window.localStorage.getItem('creatturAccessToken')
  return accessToken ? true : false
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin())
  const [userData] = useState({})
  const { activate, account, chainId, deactivate } = useWeb3React()
  const [yourWalletBalance, setYourWalletBalance] = useState(0)
  const connectToWallet = (data) => {
    const connector = injected
    window.sessionStorage.removeItem('walletName')
    window.sessionStorage.setItem('walletName', 'METAMASK')

    if (connector && connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        console.log('error', error.message)
        // setErrorMsg(error.message + " Please install " + data.name);
        activate(connector)
      }
    })
  }
  useEffect(() => {
    if (window.sessionStorage.getItem('walletName')) {
      connectToWallet()
    }
  }, [])
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler()
        }
      }
    }
  }, [chainId, account])
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      })
    } catch (error) {
      console.log('ERROR', error)

      // toast.warn(error.message)
      // if (error.code === 4902) {
      //   addNetworkHandler();
      // }
    }
  }
  const disconnectWalletHandler = async () => {
    try {
      deactivate()
    } catch (err) {
      console.log(err)
    }
  }
  const getUserbalce = async () => {
    const web3 = await getWeb3Obj()
    const balance = await web3.eth.getBalance(account)
    const balanceImETH = await web3.utils.fromWei(balance)
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2))
  }
  console.log('balance------->>>>', yourWalletBalance)
  useEffect(() => {
    if (account) {
      // connectToWallet()
      getUserbalce()
    } else {
      setIsLogin(false)
      // setUserData();
    }
  }, [account])
  let data = {
    userLoggedIn: isLogin,
    userData,
    yourWalletBalance,
    userLogIn: (type, data) => {
      setSession(data)
      setIsLogin(type)
    },
    connectToWallet: () => connectToWallet(),
    disconnectWalletHandler: () => disconnectWalletHandler(),
  }

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  )
}
