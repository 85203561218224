import { Contract } from '@ethersproject/contracts'
import { RPC_URL } from 'src/constants'
import Web3 from 'web3'
export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`
  return sortAdd
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account))
}
export const getWeb3Provider = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  return httpProvider
}

export const getWeb3Obj = async () => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
  const web3 = await new Web3(httpProvider)
  return web3
}

export const getWeb3ContractObject = async (abi, contractAddress) => {
  const web3 = await getWeb3Obj()
  const contract = await new web3.eth.Contract(abi, contractAddress)
  return contract
}

export const getBalanceOf = async (abi, address, account) => {
  try {
    const contract = await getWeb3ContractObject(abi, address)
    const balanceOf = await contract.methods.balanceOf(account).call()
    return balanceOf.toString()
  } catch (error) {
    console.log('ERROR', error)

    return 0
  }
}
